
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { rules, validateRules } from '@/utils/rules'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import industriesClient from '@/services/industries'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { EventBus } from '@/utils/eventBus'
import { Industry } from '@/models/dto/Industry'
import sidebar from '@/store/modules/sidebar'
import auth from '@/store/modules/auth'

@Component({
  components: { CUSimpleTable, AutocompleteAddress },
})
export default class IndustryForm extends Vue {
  @Prop({ required: false, default: null }) readonly industryId: number

  sidebar = sidebar
  rules = rules
  auth = auth

  submitting = false
  loading = false
  industry: Partial<Industry> = new Industry()

  @Watch('industryId', { immediate: true })
  onIndustryIdChanged(): void {
    this.loadIndustry()
  }

  get isModeEdit(): boolean {
    return !!this.industryId
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    this.submitting = true
    const industryRequest: Partial<Industry> = {
      companyId: this.auth.getCompanyId,
      industryId: this.industry.industryId,
      industryTypeId: 1,
      isOther: false,
      label: this.industry.label,
      parentIndustryId: null,
      subIndustryIds: [],
    }

    let request
    if (this.isModeEdit) {
      request = industriesClient.modifyIndustry(industryRequest)
    } else {
      request = industriesClient.addIndustry(industryRequest)
    }

    try {
      await request
      sidebar.pop()
      EventBus.$emit(
        'snackbar:success',
        `Industry ${this.isModeEdit ? 'modified' : 'created'} successfully!`
      )
      EventBus.$emit('refresh-tableview')
    } catch (e: any) {
      EventBus.$emit(
        'snackbar:error',
        `There was a problem ${
          this.isModeEdit ? 'modifying' : 'creating'
        } the industry`
      )
    } finally {
      this.submitting = false
    }
  }

  async loadIndustry(): Promise<void> {
    if (!this.industryId) {
      return
    }
    this.loading = true
    try {
      const response = await industriesClient.getIndustry(this.industryId)
      this.industry = response.data
    } catch (e: any) {
      EventBus.$emit(
        'snackbar:error',
        'There was a problem loading the industry'
      )
      sidebar.pop()
    } finally {
      this.loading = false
    }
  }
}


import { Vue, Component } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import industriesClient from '@/services/industries'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import sidebar from '@/store/modules/sidebar'
import IndustryForm from './IndustryForm.vue'
import { EventBus } from '@/utils/eventBus'
import { Industry } from '@/models/dto/Industry'
import { TableAction } from '@/models/TableAction'
import messages from '@/data/messages'
import HoldUpModal from './HoldUpModal.vue'

@Component({
  components: { CUCollectionTable, HoldUpModal },
})
export default class IndustriesList extends Vue {
  tableView = industriesClient.tableView
  tableItemCount = 0

  selectedIndustries: Industry[] = []
  isModalOpen = false

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '98a257c7-fb25-4072-b729-8515b8fa4708',
      text: 'Industry ID',
      value: 'industryId',
    },
    {
      _t_id: 'bbb7ce4d-27e4-468c-a721-c5624973f303',
      text: 'Industry',
      value: 'label',
      type: 'link',
      clickEvent: 'industries:open-industry-detail',
    },
  ]

  deleteIndustries(industries: Industry[]): void {
    this.isModalOpen = true
    this.selectedIndustries = industries
  }

  async handleDeleteIndustries(): Promise<void> {
    try {
      const promises = this.selectedIndustries
        .map(({ industryId }) => industryId)
        .map(industriesClient.deleteIndustry)
      await Promise.all(promises)
      EventBus.$emit('refresh-tableview')
      EventBus.$emit('snackbar:success', messages.success.industriesDeleted)
    } catch (e) {
      console.error(e)
      EventBus.$emit('snackbar:error', messages.errors.industriesDeleted)
    } finally {
      this.selectedIndustries = []
    }
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.deleteIndustries,
    },
  ]

  handleAddIndustry(): void {
    sidebar.push({
      title: 'Add Industry',
      component: IndustryForm,
    })
  }

  openIndustryDetail(row: Industry): void {
    sidebar.popAllAndPush({
      title: 'Edit Industry',
      component: IndustryForm,
      props: {
        industryId: row.industryId,
        isModeEdit: true,
      },
    })
  }

  mounted(): void {
    EventBus.$on('industries:open-industry-detail', this.openIndustryDetail)
  }

  beforeDestroy(): void {
    EventBus.$off('industries:open-industry-detail', this.openIndustryDetail)
  }
}
